import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./navigation.scss";
import {
  MdDashboard,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdCarRental,
  MdWork,
  MdCarRepair,
  MdEmail,
} from "react-icons/md";
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import { BsNewspaper } from "react-icons/bs";
import {
  FaBuysellads,
  FaUserCog,
  FaUserTag,
  FaCar,
  FaTags,
  FaQuestionCircle,
  FaComment,
  FaMotorcycle,
} from "react-icons/fa";
import { RiMotorbikeFill, RiMotorbikeLine } from "react-icons/ri";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { ImBriefcase } from "react-icons/im";
import { BiPackage } from "react-icons/bi";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineFieldNumber, AiFillDollarCircle, AiFillBank } from "react-icons/ai";
import { colorScheme } from "../contants/variables";
import { UserContext } from "../stores/contexts/userContext";

const LeftSideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [expandListingNav, setExpandListingNav] = useState(false);
  const [expandBidListingNav, setExpandBidListingNav] = useState(false);
  const [expandAdListingNav, setExpandAdListingNav] = useState(false);
  const [expandRentalListingNav, setExpandRentalListingNav] = useState(false);
  const [expandBidCommentListingNav, setExpandBidCommentListingNav] = useState(false);
  const [expandEmailListingNav, setExpandEmailListingNav] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currentActiveMenu, setCurrentActiveMenu] = useState("");
  const [toggleMenuLink, setToggleMenuLink] = useState("");
  const [userConfig] = useContext(UserContext);
  const userPermissions = userConfig.userDetails.user_permissions;

  useEffect(() => {
    if (location.pathname.includes("/edit")) {
      setIsEdit(true);
      redirect();
    } else {
      setIsEdit(false);
      redirect();
    }
  }, [location.pathname]);

  const redirect = () => {
    routes.forEach((el) => {
      if (location.pathname.includes(el.url) && !el.show) {
        navigate("../no-access", { replace: true });
      }
    });
  };

  const routes = [
    { url: "dashboard", label: "Dashboard", Icon: <MdDashboard />, show: true },
    {
      url: "bike-bids",
      label: "Manage BikeBids",
      Icon: <RiMotorbikeFill />,
      show: userPermissions.includes("car_ads_management"),
      subLinks: [
        { url: "verified", label: "Verified BikeBids" },
        { url: "unverified", label: "Unverified BikeBids" },
        { url: "sold", label: "Sold BikeBids" },
        {
          url: isEdit ? "edit" : "create",
          label: isEdit ? "Edit BikeBids" : "Add BikeBids",
        },
      ],
    },
    {
      url: "bikes",
      label: "Manage Bikes",
      Icon: <RiMotorbikeFill />,
      show: userPermissions.includes("car_ads_management"),
      subLinks: [
        { url: "verified", label: "Verified Used Bikes" },
        { url: "unverified", label: "Unverified Used Bikes" },
        // { url: "verified-commercial", label: "Verified Commercial Bikes" },
        // { url: "unverified-commercial", label: "Unverified Commercial Bikes" },
        { url: "verified-new", label: "Verified New Bikes" },
        { url: "unverified-new", label: "Unverified New Bikes" },
        { url: "sold", label: "Sold Bikes" },
        { url: "draft", label: "Draft Bikes" },
        {
          url: isEdit ? "edit" : "create",
          label: isEdit ? "Edit Used Bike" : "Add Used Bike",
        },
        
      ],
    },
    {
      url: "rentals",
      label: "Manage Rentals",
      Icon: <RiMotorbikeFill />,
      show: userPermissions.includes("car_rental"),
      subLinks: [
        { url: "verified", label: "Verified Bikes" },
        { url: "unverified", label: "Unverified Bikes" },
        {
          url: isEdit ? "edit" : "create",
          label: isEdit ? "Edit Ad" : "Create Ad",
        },
      ],
    },
    {
      url: "brands",
      label: "Manage Brands",
      Icon: <FaTags />,
      show: userPermissions.includes("brand_management"),
    },
    {
      url: "ads",
      label: "Manage Banner",
      Icon: <FaBuysellads />,
      show: userPermissions.includes("banner_management"),
      subLinks: [
        { url: "all-banner", label: "All Banner" },
        // { url: "landing-page", label: "Landing Page" },
        // { url: "product-page", label: "Product Page" },
        // { url: "other-page", label: "Other Page" },
      ],
    },
    {
      url: "dealers",
      label: "Dealers",
      Icon: <FaUserTag />,
      show: userPermissions.includes("dealer_management"),
    },
    {
      url: "direct-sellers",
      label: "Direct sellers",
      Icon: <FaUserTag />,
      show: userPermissions.includes("private_seller_management"),
    },
    {
      url: "bidding-dealers",
      label: "Bidding Dealers",
      Icon: <FaUserTag />,
      show: userPermissions.includes("dealer_management"),
    },
    {
      url: "bidding-direct-sellers",
      label: "Sell By Bidding",
      Icon: <FaUserTag />,
      show: userPermissions.includes("private_seller_management"),
    },
    {
      url: "bidding-comments",
      label: "Manage Comment",
      Icon: <FaComment />,
      show: userPermissions.includes("dealer_management"),
      subLinks: [
        { url: "verified", label: "Verified Comment" },
        { url: "unverified", label: "Unverified Comment" },
        { url: "verified-bid-price", label: "Verified Bid Price" },
        { url: "unverified-bid-price", label: "Unverified Bid Price" },
        // {
        //   url: isEdit ? "edit" : "create",
        //   label: isEdit ? "Edit Ad" : "Create Ad",
        // },
      ],
    },
    {
      url: "users",
      label: "Manage Users",
      Icon: <FaUserCog />,
      show: userPermissions.includes("user_management"),
    },
    {
      url: "marketing-emails",
      label: "Manage Emails",
      Icon: <MdEmail />,
      show: userPermissions.includes("dealer_management"),
      subLinks: [
        { url: "verified", label: "Verified Emails" },
        { url: "unverified", label: "Unverified Emails" },
      ],
    },
    {
      url: "articles",
      label: "Manage Articles",
      Icon: <BsNewspaper />,
      show: userPermissions.includes("article_management"),
    },
    {
      url: "packages",
      label: "Manage Packages",
      Icon: <BiPackage />,
      show: userPermissions.includes("package_management"),
    },
    {
      url: "careers",
      label: "Careers",
      Icon: <ImBriefcase />,
      show: userPermissions.includes("career_management"),
    },
    {
      url: "plate-numbers",
      label: "Plate Numbers",
      Icon: <AiOutlineFieldNumber />,
      show: userPermissions.includes("plate_number_management"),
    },
    {
      url: "bike-workshops",
      label: "Bike Workshops",
      Icon: <FaMotorcycle />,
      show: true,
    },
    {
      url: "bike-accessories",
      label: "Bike Accessories",
      Icon: <GiFullMotorcycleHelmet />,
      show: true,
    },
    {
      url: "coe",
      label: "Manage COE",
      Icon: <AiFillDollarCircle />,
      show: userPermissions.includes("coe_management"),
    },
    {
      url: "banks",
      label: "Manage Banks",
      Icon: <AiFillBank />,
      show: userPermissions.includes("bank_management"),
    },
    {
      url: "insurances",
      label: "Manage Insurances",
      Icon: <AiFillBank />,
      show: userPermissions.includes("insurance_management"),
    },
    {
      url: "faq",
      label: "Manage FAQ",
      Icon: <FaQuestionCircle />,
      show: userPermissions.includes("faq_management"),
    },
    {
      url: "about",
      label: "Manage About Company",
      Icon: <FaQuestionCircle />,
      show: userPermissions.includes("about_management"),
    },
    {
      url: "announcement",
      label: "Announcements",
      Icon: <HiOutlineSpeakerphone />,
      show: userPermissions.includes("announcement"),
    },
    {
      url: "trigger",
      label: "Trigger SMS",
      Icon: <RiSendPlaneFill />,
      show: userPermissions.includes("trigger_sms"),
    },
  ];

  const toggleListExpand = () => {
    setExpandListingNav(!expandListingNav);
  };

  const toggleBidListExpand = () => {
    setExpandBidListingNav(!expandBidListingNav);
  };

  const toggleAdListExpand = () => {
    setExpandAdListingNav(!expandAdListingNav);
  };
  
  const toggleRentalListExpand = () => {
    setExpandRentalListingNav(!expandRentalListingNav);
  };
  
  const toggleBidCommentListExpand = () => {
    setExpandBidCommentListingNav(!expandBidCommentListingNav);
  };
  
  const toggleEmailListExpand = () => {
    setExpandEmailListingNav(!expandEmailListingNav);
  };

  // useEffect(() => {
  //   if (location.pathname.includes("/bikes")) {
  //     setExpandListingNav(true);
  //   } else if(location.pathname.includes("/bike-bids")) {
  //     setExpandBidListingNav(true);
  //   } else if(location.pathname.includes("/ads")) {
  //     setExpandAdListingNav(true);
  //   } else if(location.pathname.includes("/bidding-comments")) {
  //     setExpandBidCommentListingNav(true);
  //   } else if(location.pathname.includes("/marketing-emails")) {
  //     setExpandEmailListingNav(true);
  //   } else {
  //     setExpandListingNav(false);
  //     setExpandBidListingNav(false);
  //     setExpandAdListingNav(false);
  //     setExpandBidCommentListingNav(false);
  //     setExpandEmailListingNav(false);
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes("/bikes")) {
      setCurrentActiveMenu('bikes');
      setToggleMenuLink('bikes');
    } else if(location.pathname.includes("/bike-bids")) {
      setCurrentActiveMenu('bike-bids');
      setToggleMenuLink('bike-bids');
    } else if(location.pathname.includes("/ads")) {
      setCurrentActiveMenu('ads');
      setToggleMenuLink('ads');
    } else if(location.pathname.includes("/bidding-comments")) {
      setCurrentActiveMenu('bidding-comments');
      setToggleMenuLink('bidding-comments');
    } else if(location.pathname.includes("/marketing-emails")) {
      setCurrentActiveMenu('marketing-emails');
      setToggleMenuLink('marketing-emails');
    } else if(location.pathname.includes("/rentals")) {
      setCurrentActiveMenu('rentals');
      setToggleMenuLink('rentals');
    } else {
      setCurrentActiveMenu('');
      setToggleMenuLink('');
    }
  }, [location.pathname]);



  return (
    <div className="nav-body">
      <div style={{ width: "90%", margin: "0 auto" }}>
        <img src={logo} width="100%" />
      </div>
      
      <div className="nav-container">
      {
          routes.filter(menu => menu.show).map((route, index) => 
            <div key={index} className={`menu-item-wrapper ${toggleMenuLink === route.url ? "open" : ""}`}>
              {
                route.subLinks ?
                <React.Fragment>
                    <div
                        className={`navlink-cars-container ${
                          currentActiveMenu.includes(route.url) ? "active" : ""
                        }`}
                        onClick={() => {
                            toggleMenuLink === route.url ? setToggleMenuLink("") : setToggleMenuLink(route.url)
                        }}
                    >
                        {route.Icon}
                        <p>{route.label}</p>
                        {toggleMenuLink === route.url ? (
                        <MdKeyboardArrowDown style={{marginRight: -7}} />
                        ) : (
                        <MdKeyboardArrowRight style={{marginRight: -7}} />
                        )}
                    </div>
                    {
                      toggleMenuLink === route.url &&
                        <div className="sub-link-container">
                          {
                            route.subLinks.map((subLink, i) => (
                              <NavLink
                                  key={i}
                                  className="sub-link"
                                  // exact
                                  to={`/${route.url}/${subLink.url}`}
                              >
                                <div className="nav-sub-link-container">
                                  <p>{subLink.label}</p>
                                </div>
                              </NavLink>
                            ))
                          }
                        </div>
                    }
                </React.Fragment>
                :
                <NavLink
                    className="normal"
                    // exact
                    to={route.url}
                    key={index}
                    >
                    <div className="navlink-container">
                        {route.Icon}
                        <p>{route.label}</p>
                    </div>
                </NavLink>
              }
            </div>
          )
        }
      </div>
      <p
        style={{
          textAlign: "center"
        }}
      >
        Version 2024.07.30.1
      </p>
    </div>
  );
};

export default LeftSideMenu;
