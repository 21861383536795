import { Row, Col, Input, Modal, Select } from "antd";
import React, { useState, useEffect, useRef } from "react";
import {
  addAccessory,
  editAccessory,
  fetchCategories,
} from "../../stores/api_calls/accessories";
import {
  fetchBrands,
} from "../../stores/api_calls/carlists";
import "./styles.scss";
import "../rentals/rentals.scss";
import RentalUploads from "../rentals/RentalUploads";
import { uploadImage } from "../../stores/api_calls/upload";
import { error, success } from "../../contants/snackbars";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { IconButton } from "../../custom_components/customButton";
import { RiImageAddFill } from "react-icons/ri";
import { fetchUsers } from "../../stores/api_calls/users";
import {
  validateUpload,
} from "../../stores/helpers/productFormValidation";
import {
  validateAccessoryForm
} from "../../stores/helpers/accessoryFormValidation";

const ImageUploadComponent = (props) => {
  const { onChange } = props;
  const fileUploader = useRef(null);

  const uploadImageCallBack = (link) => {
    onChange(link, "auto", "100%", "uploaded image");
  };

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    // const localUrl = URL.createObjectURL(file)

    const upload = uploadImage(file);

    upload
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          const url = res.data.data.metadata.image_url;
          uploadImageCallBack(url);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Upload Failed. Please try again`
        );
      });
  };

  const handleOpenGallery = (event) => {
    fileUploader.current.click();
  };

  return (
    <div>
      <div className="icon-button-div">
        <IconButton
          className="icon-button upload"
          tootTipTitle="Upload Image"
          Icon={<RiImageAddFill />}
          onClick={handleOpenGallery}
        />
      </div>

      <input
        ref={fileUploader}
        hidden
        type="file"
        name="image-event"
        id="image-event"
        accept="image/*"
        onChange={onSelectFile}
      />
    </div>
  );
};

const AccessoryForm = (props) => {
  
  const { isEdit, data, onCancel, visible, fetchData, tablePagination } = props;
  
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [convertedContent, setConvertedContent] = useState(null);
  const [dealers, setDealers] = useState({ loading: false, data: [] });
  const [enableSubmit, setEnableSubmit] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [form, setForm] = useState({
    imageUrls: [],
    dealerId: "",
    title: "",
    price: "",
    condition: "",
    categories: [],
    suitable_for: [],
    description: "",
  });

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const handleChangeCondition = (value) => {
    setForm({ ...form, condition: value });
  };

  const handleChangeCategory = (value) => {
    setForm({ ...form, categories: value });
  };

  const handleChangeBrand = (value) => {
    setForm({ ...form, suitable_for: value });
  };

  const handleDropdownChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  useEffect(() => {
    handleFetchCategories();
    handleFetchBrands();
    getDealers();
  }, []);

  useEffect(() => {
    if (isEdit) {
      console.log(data);
      setForm({
        ...form,
        id: data.id,
        imageUrls: data.images,
        dealerId: data.advertised_by.$oid,
        fullname: data.advertisement_contact_details?.name,
        title: data.title,
        price: data.price,
        condition: data.condition,
        categories: data.categories,
        suitable_for: data.suitable_for,
        description: data.description,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if ((selectedFiles || form.imageUrls)) {
      const value = validateUpload(selectedFiles, form.imageUrls);
      if (value) {
        setEnableSubmit(true);
      } else {
        setEnableSubmit(false);
      }
    }
  }, [selectedFiles, form.imageUrls]);

  const refetchLists = () => {
    const pagination = {
      page: tablePagination.current,
      limit: tablePagination.pageSize,
      keyword: "",
      latest_car_subscribe: true,
    };
    fetchData(pagination);
  };

  const getDealers = () => {
    setDealers({ ...dealers, loading: true });
    const pagination = {
      page: 1,
      limit: 100000,
      keyword: "",
      // roleIds: roles.filter((el)=>roleNames.includes(el.name)).map((el)=>el._id),
      roleIds: ["619e5d55e7040000a2007b08", "619e5d67e7040000a2007b09"],
      isVerified: true,
    };

    const users = fetchUsers(pagination);
    users
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          const responseUserData = res.data?.data;
          const accessoriesUser = responseUserData.filter((item)=> item.is_accessories_user == true);
          setDealers({ ...dealers, loading: false, data: accessoriesUser });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  
  const handleFetchCategories = () => {
    const filter = {
      keyword: "",
      page: 1,
      limit: 500,
    };
    const getCategories = fetchCategories(filter);

    getCategories
      .then((res) => {
        if (res.data.success) {
          const transformData = res.data.data.map((item) => ({
            ...item,
            label: item.name,
            value: item.name,
          }));
          setCategories(transformData);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const handleFetchBrands = () => {
    const filter = {
      keyword: "",
      page: 1,
      limit: 500,
    };
    const getBrands = fetchBrands(filter);

    getBrands
      .then((res) => {
        if (res.data.success) {
          const transformData = res.data.data.map((item) => ({
            ...item,
            label: item.name,
            value: item.name,
          }));
          const transformBrand = [
            {
              label: "All Model",
              value: "All Model",
            },
            ...transformData
          ];
          setBrands(transformBrand);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const handleSubmit = () => {
    setRequestLoading(true);

    const description = convertToRaw(editorState.getCurrentContent());
    const imagesIds = isEdit
      ? form.imageUrls.map((img) => img._id["$oid"])
      : [];

    if (selectedFiles.length) {
      selectedFiles.forEach((file, index) => {
        const upload = uploadImage(file, index + 1);

        upload.then((res) => {
          if (res.data.success) {
            imagesIds.push(res.data.data._id);
          }

          if (
            imagesIds.length - form.imageUrls.length ===
            selectedFiles.length
          ) {
            const createAccessory = isEdit
              ? editAccessory({
                  ...form,
                  imagesIds,
                  description,
                  // car_workshop_id: data.id,
                })
              : addAccessory({
                  ...form,
                  imagesIds,
                  description,
                  // car_workshop_id: data.id,
                });
            createAccessory
              .then((res) => {
                console.log(res.data);
                if (res?.data?.success) {
                  success(
                    `Bike accessory ${isEdit ? "updated" : "added"} successfully`
                  );
                  resetForm();
                  onClose();
                  refetchLists();

                  setRequestLoading(false);
                }
              })
              .catch((e) => {
                setRequestLoading(false);
                error(e.response.data.message);
              });
          }
        });
      });
    } else {
      const createAccessory = isEdit
              ? editAccessory({
                  ...form,
                  description,
                  // car_workshop_id: data.id,
                })
              : addAccessory({
                  ...form,
                  description,
                  // car_workshop_id: data.id,
                });
            createAccessory
              .then((res) => {
                console.log(res.data);
                if (res?.data?.success) {
                  success(
                    `Bike accessory ${isEdit ? "updated" : "added"} successfully`
                  );
                  resetForm();
                  onClose();
                  refetchLists();

                  setRequestLoading(false);
                }
              })
              .catch((e) => {
                console.log(e.response);
                setRequestLoading(false);
                error(e.response.data.message);
              });
    }
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const resetForm = () => {
    setForm({ imageUrls: [] });
    // setForm({ imageUrls: [], car_workshop_id: data.id });
  };

  const onClose = () => {
    setForm({
      ...form,
      imageUrls: [],
      dealerId: "",
      fullname: null,
      title: "",
      price: "",
      condition: "",
      categories: [],
      suitable_for: [],
      description: "",
    });

    onCancel();
  };

  return (
    <div>
      <Modal
        className="formModal"
        title={`Add accessory`}
        visible={visible}
        onCancel={onClose}
        width={800}
        onOk={handleSubmit}
        okText="Submit"
        confirmLoading={requestLoading}
        okButtonProps={{ disabled: !validateAccessoryForm(form) || !enableSubmit }}
      >
        <div>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <div className="form-container">
                <p className="form-label">
                  Dealer Name <span>*</span> :{" "}
                </p>
                <Select
                  showSearch
                  value={form.fullname || undefined}
                  loading={dealers.loading}
                  onChange={(value) => handleDropdownChange("dealerId", value)}
                  style={{ width: "100%" }}
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dealers.data.map((el, i) => (
                    <Select.Option key={i} value={el._id}>
                      {`${el.user_first_name} ${el.user_last_name}`}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="form-container">
                <p className="form-label">
                  Accessory Name <span>*</span> :{" "}
                </p>
                <Input
                  value={form.title}
                  onChange={(text) => handleFieldChange("title", text)}
                  placeholder="Accessory Name"
                />
              </div>
              <div className="form-container">
                <p className="form-label">
                  Price <span>*</span> :{" "}
                </p>
                <Input
                  value={form.price}
                  onChange={(text) => handleFieldChange("price", text)}
                  placeholder="Price"
                />
              </div>
              <div className="form-container">
                <p className="form-label">
                  Condition <span>*</span> :
                </p>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={form.condition}
                  placeholder="Select condition"
                  defaultValue={[]}
                  onChange={handleChangeCondition}
                  optionLabelProp="label"
                  options={[
                    { label: "Brand New", value: "Brand New" },
                    { label: "Used", value: "Used" },
                  ]}
                  optionRender={(option) => <Space>{option.data.label}</Space>}
                />
              </div>

              <div className="form-container">
                <p className="form-label">
                  Categories <span></span> :{" "}
                </p>
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  value={form.categories}
                  placeholder="select categories"
                  defaultValue={[]}
                  onChange={handleChangeCategory}
                  optionLabelProp="label"
                  options={categories}
                  optionRender={(option) => <Space>{option.data.name}</Space>}
                />
              </div>

              <div className="form-container">
                <p className="form-label">
                  Suitable for <span></span> :{" "}
                </p>
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  value={form.suitable_for}
                  placeholder="select brands"
                  defaultValue={[]}
                  onChange={handleChangeBrand}
                  optionLabelProp="label"
                  options={brands}
                  optionRender={(option) => <Space>{option.data.name}</Space>}
                />
              </div>

              {/* <div className="form-fields">
                <p className="form-field-label">
                  Description <span></span> :{" "}
                </p>
                <Input.TextArea
                  rows={8}
                  value={form.description}
                  onChange={(text) => handleFieldChange("description", text)}
                />
              </div> */}

              <div className="form-fields">
                <p className="form-field-label">
                  Description <span></span> :{" "}
                </p>
              </div>

              <div className="editor-container">
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleEditorChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    inline: { inDropdown: false },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    // image: {
                    //   component: ImageUploadComponent,
                    //   previewImage: true,
                    //   uploadEnabled: true,
                    //   alt: { present: true, mandatory: true },
                    // },
                  }}
                />
              </div>
            </Col>
          </Row>


          <div
                className='upload-header'
            >
                <div className='header-div'>
                    <p className='header-text'>Upload Pictures <span>*</span> :{" "}</p>
                    <p className='sub-header-text'>
                    Upload requirements: You can upload upto 9 images. Total image file size should not exceed <span style={{color:'red',fontWeight:'bold'}}>10MB</span>. 
                    <span style={{color:'red',fontWeight:'bold'}}> Please noted that only PNG & JPG images are allowed. Image dimension (600 width x 400 height)pixels.</span>
                    </p>
                </div>
          </div>
          <div className="rental-form-container">
            <RentalUploads
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              form={form}
              setForm={setForm}
              isEdit={isEdit}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AccessoryForm;
